<template>
  <div class="item-total" @click="clickProductItem">
    <img :src="img_src" class="image" />
    <div class="place">{{ place }}</div>
    <div class="title">
      {{ title }}
    </div>
    <div class="tag-array">
      <div class="tag" v-for="(item, index) in tag_array" :key="index">
        {{ item }}
      </div>
    </div>
    <div class="price-bg" v-if="price == 0">
      <div class="price-bar">
        <div class="price-tag2">{{ isfree ? "免费景区" : "敬请期待" }}</div>
      </div>
    </div>
    <div class="price-bg" v-else>
      <div class="price-bar">
        <div class="price-tag">RMB</div>
        <div class="price">{{ price }}</div>
        <div class="price-tag2">起</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: Number,
    index: Number,
    img_src: String,
    title: String,
    price: String,
    place: String,
    tag_array: Array,
    isfree: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    clickProductItem() {
      this.$emit("clickProductItem", {
        type: this.$props.type,
        index: this.$props.index,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.item-total:hover {
  border: 1px solid var(--hover_color);
}
.item-total {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  width: 285px;
  height: 330px;
  margin-right: 13px;
  background-color: #fff;
  position: relative;
  border: 1px solid #f1f1f1;
  margin-bottom: 20px;
  .price-bg {
    margin-left: auto;
    margin-right: 16px;
    margin-bottom: 14px;
  }
  div.price-bar {
    display: flex;
    div.price-tag {
      font-size: 20px;
      line-height: 24px;
      color: #666666;
      opacity: 1;
    }

    div.price {
      font-size: 20px;
      margin-left: 4px;
      line-height: 24px;
      color: #e32828;
      opacity: 1;
    }

    div.price-tag2 {
      font-size: 14px;
      margin-left: 4px;
      line-height: 17px;
      margin-top: 4px;
      color: #666666;
      opacity: 1;
    }
  }
  .image {
    width: 100%;
    height: 190px;
  }
  .place {
    top: 16px;
    left: 16px;
    position: absolute;
    font-size: 14px;
    line-height: 28px;
    padding-left: 12px;
    padding-right: 12px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    color: #ffffff;
    opacity: 1;
  }
  .title {
    margin-top: 16px;
    margin-left: 16px;
    width: 253px;
    height: 40px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    opacity: 1;
  }
  .tag-array {
    display: flex;
    flex-wrap: nowrap;
    margin-left: 16px;
    margin-top: 14px;
    width: 253px;
    overflow: hidden;
    .tag {
      white-space: nowrap;
      margin-right: 8px;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 8px;
      color: #f0848c;
      opacity: 1;
      padding-left: 11px;
      padding-right: 11px;
      background: #fff2f3;
      opacity: 1;
      border-radius: 2px;
    }
  }
}
</style>