<template>
	<div class="destination-total">
		<img class="img-bg" :src="img_bg" />
		<div class="title" v-if="title != null && type == 1">{{ title }}</div>
		<div class="title title3" v-else-if="title != null && type == 3">
			{{ title }}
		</div>
		<div class="title title4" v-else-if="title != null && type == 4">
			{{ title }}
		</div>
		<div class="title title6" v-else-if="title != null && type == 6">
			{{ title }}
		</div>
		<div class="title title7" v-else-if="title != null && type == 7">
			{{ title }}
		</div>
		<div class="title title3" v-else-if="title != null && type == 8">
			{{ title }}
		</div>
		<div class="array">
			<div class="item" v-for="(item, index) in array" :key="item.id">
				• {{ item.name }}
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			type: Number,
			title: String,
			img_bg: String,
			array: Array,
		},
		data() {
			return {};
		},
		methods: {},
	};
</script>

<style lang="less" scoped>
	.destination-total {
		width: 285px;
		height: 330px;
		margin-right: 13px;
		background-color: #fff;
		position: relative;
		border: 1px solid #f1f1f1;

		.array {
			display: flex;
			flex-wrap: wrap;
			position: absolute;
			top: 82px;
			left: 30px;

			.item {
				width: 92px;
				cursor: pointer;
				margin-bottom: 17px;
				height: 16px;
				font-size: 16px;
				margin-right: 10px;
				color: #fff;
				opacity: 1;
			}

			.item:hover {
				color: #666;
			}
		}

		.title {
			position: absolute;
			top: 24px;
			left: 20px;
			width: 133px;
			height: 32px;
			color: #fff;
			font-weight: bold;
			font-size: 20px;
			line-height: 32px;
			text-align: center;
			background: #809eff;
			opacity: 1;
			border-radius: 16px;
		}

		.title3 {
			background-color: #66c68b;
		}

		.title4 {
			background-color: #7c51e6;
		}

		.title6 {
			background-color: #507ee7;
		}

		.title7 {
			background-color: #eb7261;
		}

		.img-bg {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			object-fit: fill;
		}
	}
</style>
