<template>
	<div class="product-list-total">
		<div class="product-bar" v-if="type == 1">
			<HomeTitleBar :type="Number('1')" title="跟团游" desc="跟  团  热  门" :hideMore="true"
				:tab_array="['国内跟团', '出境游','省内跟团']" :tab_index.sync="tab_index"></HomeTitleBar>
			<div class="product-more-bar">
				<HomeProductDestination title="热门目的地" :type="Number('1')" :array="destination_group" v-if="search == ''"
					:img_bg="require('@/assets/kh/gty_rm@2x.png')"></HomeProductDestination>
				<HomeProductItem v-for="(item, index) in product_group" :type="Number('1')" :index="index"
					:key="item.id" :img_src="item.picturePath" :title="item.title" :place="item.cfd"
					:price="item.refPrice + ''" :tag_array="item.tag_array" @clickProductItem="clickProductItem">
				</HomeProductItem>
			</div>
		</div>
		<div class="product-bar" v-if="type == 16">
			<HomeTitleBar :type="Number('16')" title="出境游" desc="跟  团  热  门" :hideMore="true" :tab_array="[]"
				:tab_index.sync="tab_index"></HomeTitleBar>
			<div class="product-more-bar">
				<HomeProductDestination title="热门目的地" :type="Number('1')" :array="destination_group" v-if="search == ''"
					:img_bg="require('@/assets/kh/gty_rm@2x.png')"></HomeProductDestination>
				<HomeProductItem v-for="(item, index) in product_group" :type="Number('16')" :index="index"
					:key="item.id" :img_src="item.picturePath" :title="item.title" :place="item.cfd"
					:price="item.refPrice + ''" :tag_array="item.tag_array" @clickProductItem="clickProductItem">
				</HomeProductItem>
			</div>
		</div>
		<div class="product-bar" v-else-if="type == 2">
			<HomeTitleBar :type="Number('2')" title="东北游" desc="当  季  热  推" :hideMore="true"
				:tab_array="['辽宁', '吉林', '黑龙江', '大连']" :tab_index.sync="tab_index"></HomeTitleBar>
			<div class="product-more-bar">
				<HomeProductItem v-for="(item, index) in product_group" :type="Number('2')" :index="index"
					:key="item.id" :img_src="item.picturePath" :title="item.title" :place="item.cfd"
					:price="item.refPrice + ''" :tag_array="item.tag_array" @clickProductItem="clickProductItem">
				</HomeProductItem>
			</div>
		</div>
		<div class="product-bar" v-else-if="type == 3">
			<HomeTitleBar :type="Number('3')" title="景点门票" desc="爆  款  推   荐" :hideMore="true" :tab_array="[]">
			</HomeTitleBar>
			<div class="product-more-bar">
				<HomeProductDestination title="热门主题" :type="Number('3')" :array="destination_ticket" v-if="search == ''"
					:img_bg="require('@/assets/kh/mp_rm@2x.png')"></HomeProductDestination>
				<HomeProductItem v-for="(item, index) in product_group" :type="Number('3')" :index="index"
					:key="item.id" :img_src="item.imageUrl" :title="item.title" :place="item.destination"
					:price="item.price + ''" :tag_array="item.tag_array" :isfree="item.isFree"
					@clickProductItem="clickProductItem"></HomeProductItem>
			</div>
		</div>
		<div class="product-bar" v-else-if="type == 18">
			<HomeTitleBar :type="Number('18')" title="海岛游" desc="蜜  月  海  岛" :hideMore="true" :tab_array="[]">
			</HomeTitleBar>
			<div class="product-more-bar">
				<!-- <HomeProductDestination title="热门主题" :type="Number('18')" :array="destination_ticket"
					v-if="search == ''" :img_bg="require('@/assets/kh/mp_rm@2x.png')"></HomeProductDestination> -->
				<HomeProductItem v-for="(item, index) in product_group" :type="Number('18')" :index="index"
					:key="item.id" :img_src="item.imageUrl" :title="item.title" :place="item.destination"
					:price="item.price + ''" :tag_array="item.tag_array" :isfree="item.isFree"
					@clickProductItem="clickProductItem"></HomeProductItem>
			</div>
		</div>
		<div class="product-bar" v-else-if="type == 4">
			<HomeTitleBar :type="Number('4')" title="研学实践课" desc="研  学  实   践   课" :hideMore="true" :tab_array="[]">
			</HomeTitleBar>
			<div class="product-more-bar">
				<HomeProductDestination title="热门目的地" :type="Number('4')" :array="destination_group"
					:img_bg="require('@/assets/kh/yx_rm@2x.png')"></HomeProductDestination>
				<HomeProductItem v-for="(item, index) in product_group" :type="Number('4')" :index="index"
					:key="item.id" :img_src="item.imageUrl" :title="item.title" place="" :price="item.price + ''"
					:tag_array="item.xqsnTheme" @clickProductItem="clickProductItem">
				</HomeProductItem>
			</div>
		</div>
		<div class="red-bar" v-else-if="type == 7">
			<img src="../assets/kh/hspx_bg@2x.png" class="red-img" />
			<img src="../assets/kh/ico_hspx2@2x.png" class="red-img2" />
			<div class="product-bar">
				<HomeTitleBar :type="Number('5')" title="红色培训" desc="" :tab_array="[]" :hideMore="true"></HomeTitleBar>
				<div class="product-more-bar">
					<HomeProductDestination :img_bg="require('@/assets/kh/hs_1@2x.png')"></HomeProductDestination>
					<HomeProductItem v-for="(item, index) in product_group" :type="Number('5')" :index="index"
						:key="item.id" :img_src="item.picturePath" :title="item.title" :place="item.cfd"
						:price="item.refPrice + ''" :tag_array="item.tag_array" @clickProductItem="clickProductItem">
					</HomeProductItem>
				</div>
			</div>
		</div>
		<div class="product-bar" v-else-if="type == 9">
			<HomeTitleBar :type="Number('6')" title="邮轮游" desc="海  上  城  堡" :tab_index.sync="tab_index"
				:tab_array="['国内邮轮', '国际邮轮']" :hideMore="true"></HomeTitleBar>
			<div class="product-more-bar">
				<HomeProductDestination title="热门目的地" :type="Number('6')" :array="destination_group" v-if="search == ''"
					:img_bg="require('@/assets/kh/yly_rm@2x.png')"></HomeProductDestination>
				<HomeProductItem v-for="(item, index) in product_group" :type="Number('6')" :index="index"
					:key="item.id" :img_src="item.image" :title="item.productName" :place="item.dep"
					:price="item.price + ''" @clickProductItem="clickProductItem"></HomeProductItem>
			</div>
		</div>
		<div class="product-bar" v-else-if="type == 10">
			<HomeTitleBar :type="Number('7')" title="火车游" desc="最  优  路  线" :tab_array="['正班火车', '专列游']"
				:tab_index.sync="tab_index" :hideMore="true"></HomeTitleBar>
			<div class="product-more-bar">
				<HomeProductDestination title="热门目的地" :type="Number('7')" :array="destination_group" v-if="search == ''"
					:img_bg="require('@/assets/kh/hc_rm@2x.png')"></HomeProductDestination>
				<HomeProductItem v-for="(item, index) in product_group" :type="Number('7')" :index="index"
					:key="item.id" :img_src="item.picturePath" :title="item.title" :place="item.cfd"
					:price="item.refPrice + ''" :tag_array="item.tag_array" @clickProductItem="clickProductItem">
				</HomeProductItem>
			</div>
		</div>
		<div class="product-bar" v-else-if="
        type == 8 ||
        type == 11 ||
        type == 12 ||
        type == 13 ||
        type == 14 ||
        type == 5
      ">
			<HomeTitleBar :type="Number('8')" title="亲子游" desc="亲  子  乐  园" :tab_array="[]" :hideMore="true"
				v-if="type == 8"></HomeTitleBar>
			<HomeTitleBar :type="Number('8')" title="房车自驾" desc="房  车  自  驾" :tab_array="['房车跟团游', '东三省房车租车']"
				:tab_index.sync="tab_index" :hideMore="true" v-else-if="type == 11"></HomeTitleBar>
			<HomeTitleBar :type="Number('8')" title="会议会展" desc="会  议  会  展" :tab_array="[]" :hideMore="true"
				v-else-if="type == 12"></HomeTitleBar>
			<HomeTitleBar :type="Number('8')" title="旅居康养" desc="健  康  旅  行" :tab_array="[]" :hideMore="true"
				v-else-if="type == 13"></HomeTitleBar>
			<HomeTitleBar :type="Number('8')" title="定制游" desc="跟  团  热  门" :tab_array="[]" :hideMore="true"
				v-else-if="type == 14"></HomeTitleBar>
			<HomeTitleBar :type="Number('8')" title="自由行" desc="当  季  热  推" :tab_array="['Clubmeb', '长隆', '酒店/公寓/民宿']"
				:tab_index.sync="tab_index" :hideMore="true" v-else-if="type == 5"></HomeTitleBar>
			<div class="product-more-bar" v-if="(type == 11 && tab_index == 1) || type == 5">
				<HomeProductDestination v-if="type == 5 && tab_index == 0" title="热门目的地" :type="Number('8')"
					:array="destination_group" :img_bg="require('@/assets/kh/clubmed_rm.png')"></HomeProductDestination>
				<HomeProductDestination v-else-if="type == 5 && tab_index == 1" title="热门目的地" :type="Number('8')"
					:array="destination_group" :img_bg="require('@/assets/kh/changlong_rm.png')">
				</HomeProductDestination>
				<HomeProductDestination v-else title="热门目的地" :type="Number('8')" :array="destination_group"
					:img_bg="require('@/assets/kh/changgui_rm.png')"></HomeProductDestination>
				<HomeProductItem v-for="(item, index) in product_group" :type="Number('8')" :index="index"
					:key="item.id" :img_src="item.imageUrl" :title="item.title" :place="item.startCity"
					:price="item.price + ''" :tag_array="item.tag_array" @clickProductItem="clickProductItem">
				</HomeProductItem>
			</div>
			<div class="product-more-bar" v-else>
				<HomeProductDestination title="热门目的地" :type="Number('8')" :array="destination_group"
					:img_bg="require('@/assets/kh/changgui_rm.png')"></HomeProductDestination>
				<HomeProductItem v-for="(item, index) in product_group" :type="Number('8')" :index="index"
					:key="item.id" :img_src="item.picturePath" :title="item.title" :place="item.cfd"
					:price="item.refPrice + ''" :tag_array="item.tag_array" @clickProductItem="clickProductItem">
				</HomeProductItem>
			</div>
		</div>
		<el-pagination class="page" v-if="total" @current-change="currentChange" background :current-page="page"
			:page-size="max_page" layout="prev, pager, next" :total="total">
		</el-pagination>
	</div>
</template>

<script>
	import {
		getGroupHotCity,
		getGroupProductList,
		getProvinceProductList,
		getCustomCityProductList,
		getTicketList,
		getThemeProductList,
		getThemeMoreCityList,
		getRedProductList,
		getShipProductList,
		getShipMoreCityList,
		getNewTitleProductList,
		getNewTitleMoreCityList,
		getSweetTheme,
		getSweetThemeDest,
		getOutboundTravelList,
		getGWTeamHotCity
	} from "@/api/index";
	import HomeProductItem from "../components/Home_Product_Item.vue";
	import HomeProductDestination from "../components/Home_Product_Destination.vue";
	import HomeTitleBar from "../components/Home_Title_Bar.vue";
	export default {
		components: {
			HomeProductItem,
			HomeProductDestination,
			HomeTitleBar,
		},
		watch: {
			tab_index(newVal, oldVal) {
				this.page = 1;
				this.product_group = [];
				if (this.type == 1) {
					//跟团游
					if (newVal == 0) {
						//国内
						this.getGroupProduct();
						this.getGroupHotCity();
					} else if (newVal == 1) {
						this.getOutboundTravel();
						this.getGWTeamHotCity()
					} else {
						//省内
						this.getProvinceProduct();
						this.getGroupHotCity();
					}
				} else if (this.type == 16) {
					// 出境游
					this.getOutboundTravel();
				} else if (this.type == 2) {
					//当地参团
					this.getCustomCityProductList(newVal);
				} else if (this.type == 10) {
					//火车
					if (newVal == 0) {
						//正班火车
						this.getNewTitleMoreCityList("30");
						this.getNewTitleProductList("30");
					} else if (newVal == 1) {
						//专列游
						this.getNewTitleMoreCityList("31");
						this.getNewTitleProductList("31");
					}
				} else if (this.type == 11) {
					//房车自驾
					if (newVal == 0) {
						//跟团
						this.getNewTitleMoreCityList("32");
						this.getNewTitleProductList("32");
					} else if (newVal == 1) {
						//门票
						this.getSweetTheme(17);
						this.getSweetThemeDest(17);
					}
				} else if (this.type == 5) {
					//房车自驾
					if (newVal == 0) {
						//clubmeb
						this.getSweetTheme(11);
						this.getSweetThemeDest(11);
					} else if (newVal == 1) {
						//长隆
						this.getSweetTheme(12);
						this.getSweetThemeDest(12);
					} else if (newVal == 2) {
						//酒店
						this.getSweetTheme(3);
						this.getSweetThemeDest(3);
					}
				} else if (this.type == 9) {
					//游轮
					if (newVal == 0) {
						//国内
						this.getShipProductList();
						this.getShipMoreCityList();
					} else if (newVal == 1) {
						//国际
					}
				}
			},
		},
		data() {
			return {
				type: 1, //1跟团游
				search: "",
				tab_name: "",
				tab_index: -1,
				product_group: [],
				destination_group: [],
				page: 1,
				max_page: 0,
				total: 0,
				destination_ticket: [{
						id: 10,
						name: "爆款推荐",
					},
					{
						id: 6,
						name: "冰雪乐园",
					},
					{
						id: 5,
						name: "温泉洗浴",
					},
					{
						id: 4,
						name: "亲子乐园",
					},
					{
						id: 3,
						name: "酒店住宿",
					},
					{
						id: 8,
						name: "自然风光",
					},
					{
						id: 2,
						name: "动植物园",
					},
					{
						id: 9,
						name: "文化古迹",
					},
				],
			};
		},
		created() {
			this.type = this.$route.query.type;
			this.tab_name = this.$route.query.tab_name;
			this.search =
				this.$route.query.search == undefined ? "" : this.$route.query.search;
			if (this.type == 1) {
				//跟团游
				if (this.tab_name == "2-1") {
					//国内跟团
					this.tab_index = 0;
					this.getGroupHotCity();
				} else if (this.tab_name == "2-3") {
					//出境游
					this.tab_index = 1;
					this.getGWTeamHotCity()
				} else if (this.tab_name == "2-2") {
					//省内跟团
					this.tab_index = 2;
					this.getGroupHotCity();
				}

			} else if (this.type == 16) {
				// 出境游
				this.getOutboundTravel();
				this.getGWTeamHotCity()
			} else if (this.type == 2) {
				//当地参团
				if (this.tab_name == "3-1") {
					//辽宁
					this.tab_index = 0;
				} else if (this.tab_name == "3-2") {
					//吉林
					this.tab_index = 1;
				} else if (this.tab_name == "3-3") {
					//黑龙江
					this.tab_index = 2;
				} else if (this.tab_name == "3-4") {
					//大连
					this.tab_index = 3;
				}
			} else if (this.type == 3) {
				//景点门票
				this.getTicketList();
			} else if (this.type == 4) {
				//研学
				this.getThemeProductList(2);
				this.getThemeMoreCityList(2);
			} else if (this.type == 7) {
				//红色之旅
				this.getRedProductList();
			} else if (this.type == 8) {
				//亲子
				this.getNewTitleMoreCityList("26");
				this.getNewTitleProductList("26");
			} else if (this.type == 9) {
				//邮轮
				if (this.tab_name == "10-1") {
					//国内邮轮
					this.tab_index = 0;
				} else if (this.tab_name == "10-2") {
					//国际邮轮
					this.tab_index = 1;
				}
			} else if (this.type == 10) {
				//火车
				if (this.tab_name == "11-1") {
					//正班火车
					this.tab_index = 0;
				} else if (this.tab_name == "11-2") {
					//专列游
					this.tab_index = 1;
				}
			} else if (this.type == 11) {
				//房车自驾
				if (this.tab_name == "12-1") {
					//跟团
					this.tab_index = 0;
				} else if (this.tab_name == "12-2") {
					//门票
					this.tab_index = 1;
				}
			} else if (this.type == 12) {
				//会议会展
				this.getNewTitleMoreCityList("29");
				this.getNewTitleProductList("29");
			} else if (this.type == 13) {
				//旅居康养
				this.getNewTitleMoreCityList("27");
				this.getNewTitleProductList("27");
			} else if (this.type == 14) {
				//定制游
				this.getNewTitleMoreCityList("28");
				this.getNewTitleProductList("28");
			} else if (this.type == 5) {
				//自由行
				if (this.tab_name == "6-1") {
					//clbmeb
					this.tab_index = 0;
				} else if (this.tab_name == "6-2") {
					//长隆
					this.tab_index = 1;
				} else if (this.tab_name == "6-3") {
					//酒店
					this.tab_index = 2;
				}
			} else if (this.type == 18) {
				//海岛游
				this.getTicketList();
			}
		},
		methods: {
			//点击产品
			clickProductItem(e) {
				// return;
				console.log(e);
				let type = e.type;
				let index = e.index;
				let productType;
				let productId;
				let tab_name;
				switch (type) {
					case 1:
						//跟团游
						productType = 1;
						productId = this.product_group[index].id;
						tab_name = this.tab_index == 0 ? "2-1" : (this.tab_index == 1 ? "2-3" : "2-2");
						break;
					case 2:
						//当地参团
						productType = 1;
						productId = this.product_group[index].id;
						tab_name = "3-" + (this.tab_index + 1);
						break;
					case 3:
						//周边游
						productType = 2;
						productId = this.product_group[index].id;
						tab_name = "4";
						break;
					case 4:
						//研学
						productType = 60;
						productId = this.product_group[index].id;
						tab_name = "5";
						break;
					case 5:
						//红色之旅
						productType = 1;
						productId = this.product_group[index].id;
						tab_name = "8";
						break;
					case 6:
						//游轮
						productType = 3;
						productId = this.product_group[index].id;
						tab_name = "10-1";
						break;
					case 7:
						//火车
						productType = 1;
						productId = this.product_group[index].id;
						tab_name = this.tab_index == 0 ? "11-1" : "11-2";
						break;
					case 8:
						//亲子 会议会展 定制游 旅居康养 房车自驾
						if ((this.type == 11 && this.tab_index == 1) || this.type == 5)
							productType = 2;
						else productType = 1;

						productId = this.product_group[index].id;

						if (this.type == 8) tab_name = "9";
						else if (this.type == 11) {
							tab_name = this.tab_index == 0 ? "12-1" : "12-2";
						} else if (this.type == 12) tab_name = "13";
						else if (this.type == 13) tab_name = "14";
						else if (this.type == 14) tab_name = "15";
						else if (this.type == 5) tab_name = "6-" + (this.tab_index + 1);
						break;
					case 16:
						//跟团游
						productType = 1;
						productId = this.product_group[index].id;
						tab_name = 16;
						break;
					case 18:
						//海岛
						productType = 2;
						productId = this.product_group[index].id;
						tab_name = "18";
						break;
				}
				let org = this.$route.query.org ? this.$route.query.org : ''
				window.open(
					"/Detail?type=" +
					productType +
					"&id=" +
					productId +
					"&tab_name=" +
					tab_name +
					"&org=" + org,
					"_blank"
				);
			},

			//当前页码改变
			currentChange(e) {
				this.page = e;
				if (this.type == 1) {
					//跟团游
					if (this.tab_index == 0) {
						//国内跟团
						this.getGroupProduct();
					} else if (this.tab_index == 1) {
						//省内跟团
						this.getOutboundTravel();
					} else if (this.tab_index == 2) {
						//省内跟团
						this.getProvinceProduct();
					}
				} else if (this.type == 16) {
					// 出境游
					this.getOutboundTravel();
				} else if (this.type == 2) {
					//当地参团
					this.getCustomCityProductList(this.tab_index);
				} else if (this.type == 3) {
					//周边游
					this.getTicketList();
				} else if (this.type == 4) {
					//研学
					this.getThemeProductList(2);
					this.getThemeMoreCityList(2);
				} else if (this.type == 7) {
					//红色之旅
					this.getRedProductList();
				} else if (this.type == 8) {
					//亲子
					this.getNewTitleMoreCityList("26");
					this.getNewTitleProductList("26");
				} else if (this.type == 9) {
					//邮轮
					if (this.tab_index == 0) {
						//国内邮轮
					} else if (this.tab_index == 1) {
						//国际邮轮
					}
					this.getShipProductList();
					this.getShipMoreCityList();
				} else if (this.type == 10) {
					//火车
					if (this.tab_index == 0) {
						//正班火车
						this.getNewTitleMoreCityList("30");
						this.getNewTitleProductList("30");
					} else if (this.tab_index == 1) {
						//专列游
						this.getNewTitleMoreCityList("31");
						this.getNewTitleProductList("31");
					}
				} else if (this.type == 11) {
					//房车自驾
					if (this.tab_index == 0) {
						//跟团
						this.getNewTitleMoreCityList("32");
						this.getNewTitleProductList("32");
					} else if (this.tab_index == 1) {
						//门票
						this.getSweetTheme(17);
						this.getSweetThemeDest(17);
					}
				} else if (this.type == 12) {
					//会议会展
					this.getNewTitleMoreCityList("29");
					this.getNewTitleProductList("29");
				} else if (this.type == 13) {
					//旅居康养
					this.getNewTitleMoreCityList("27");
					this.getNewTitleProductList("27");
				} else if (this.type == 14) {
					//定制游
					this.getNewTitleMoreCityList("28");
					this.getNewTitleProductList("28");
				} else if (this.type == 5) {
					//自由行
					if (this.tab_index == 0) {
						//clbmeb
						this.getSweetTheme(11);
						this.getSweetThemeDest(11);
					} else if (this.tab_index == 1) {
						//长隆
						this.getSweetTheme(12);
						this.getSweetThemeDest(12);
					} else if (this.tab_index == 2) {
						//酒店
						this.getSweetTheme(3);
						this.getSweetThemeDest(3);
					}
				} else if (this.type == 18) {
					//周边游
					this.getTicketList();
				}
			},

			//获得指定主题目的地列表
			getNewTitleMoreCityList(id) {
				getNewTitleMoreCityList(id).then((res) => {
					this.destination_group = [];
					res.objects.forEach((e) => {
						this.destination_group.push({
							id: e.areaid,
							name: e.areaname,
						});
					});
				});
			},

			getSweetThemeDest(theme) {
				getSweetThemeDest(theme).then((res) => {
					this.destination_group = [];
					res.forEach((e) => {
						this.destination_group.push({
							id: e.key,
							name: e.value,
						});
					});
					this.destination_group.splice(14);
				});
			},

			//获得甜程门票指定主题产品列表
			getSweetTheme(theme) {
				getSweetTheme(theme, this.page).then((res) => {
					this.max_page = 15;
					this.total = res.result.tag.count;
					res.result.tag.tagProductList.forEach((e) => {
						let tag_array = [];
						tag_array.push(e.themeName);
						e.tag_array = tag_array;
					});
					this.product_group = res.result.tag.tagProductList;
				});
			},

			//获得指定主题列表 新主题
			getNewTitleProductList(theme) {
				let platformid;
				if (this.$app == "dy") platformid = "8";
				else if (this.$app == "kh") platformid = "7";
				getNewTitleProductList(
					platformid,
					theme,
					this.page,
					this.search == "" ? 15 : 16,
					this.search
				).then((res) => {
					this.max_page = this.search == "" ? 15 : 16;
					this.total = res.total;
					res.objects.forEach((ele) => {
						ele.pd_json = ele.pd_json != "" ? JSON.parse(ele.pd_json) : "";
						if (ele.pd_json != "") {
							ele.cfd = "";
							ele.pd_json.pod.forEach((le, i) => {
								if (i + 1 == ele.pd_json.pod.length) {
									ele.cfd = ele.cfd + le.Pod;
								} else {
									ele.cfd = ele.cfd + le.Pod + "、";
								}
							});
						}
						let tag_array = [];
						ele.strengths.forEach((e) => {
							tag_array.push(e.strength);
						});
						ele.tag_array = tag_array;
					});
					this.product_group = res.objects;
				});
			},

			//获得游轮更多目的地列表
			getShipMoreCityList() {
				getShipMoreCityList().then((res) => {
					this.destination_group = [];
					res.data.des.forEach((e) => {
						this.destination_group.push({
							id: e.key,
							name: e.value,
						});
					});
				});
			},

			//获得游轮产品列表
			getShipProductList() {
				getShipProductList(
					this.page,
					this.search == "" ? 15 : 16,
					this.search
				).then((res) => {
					this.max_page = this.search == "" ? 15 : 16;
					this.total = res.dataCount;
					this.product_group = res.data;
				});
			},

			//获得红色之旅产品列表
			getRedProductList() {
				getRedProductList(this.page, 15).then((res) => {
					this.max_page = 15;
					this.total = res.total;
					res.objects.forEach((ele) => {
						ele.pd_json = ele.pd_json != "" ? JSON.parse(ele.pd_json) : "";
						if (ele.pd_json != "") {
							ele.cfd = "";
							ele.pd_json.pod.forEach((le, i) => {
								if (i + 1 == ele.pd_json.pod.length) {
									ele.cfd = ele.cfd + le.Pod;
								} else {
									ele.cfd = ele.cfd + le.Pod + "、";
								}
							});
						}
						let tag_array = [];
						ele.strengths.forEach((e) => {
							tag_array.push(e.strength);
						});
						ele.tag_array = tag_array;
					});
					this.product_group = res.objects;
				});
			},

			//获得指定主题目的地列表
			getThemeMoreCityList(id) {
				getThemeMoreCityList(id).then((res) => {
					this.destination_group = [];
					res.objects.forEach((e) => {
						this.destination_group.push({
							id: e.areaid,
							name: e.areaname,
						});
					});
				});
			},

			//获得指定主题产品列表
			// getThemeProductList(id) {
			// 	let platformid;
			// 	if (this.$app == "dy") platformid = "8";
			// 	else if (this.$app == "kh") platformid = "7";
			// 	console.log(this.search);
			// 	getThemeProductList(
			// 		platformid,
			// 		id,
			// 		this.page,
			// 		this.search == "" ? 15 : 16,
			// 		this.search
			// 	).then((res) => {
			// 		this.max_page = this.search == "" ? 15 : 16;
			// 		this.total = res.total;
			// 		res.objects.forEach((ele) => {
			// 			ele.pd_json = ele.pd_json != "" ? JSON.parse(ele.pd_json) : "";
			// 			if (ele.pd_json != "") {
			// 				ele.cfd = "";
			// 				ele.pd_json.pod.forEach((le, i) => {
			// 					if (i + 1 == ele.pd_json.pod.length) {
			// 						ele.cfd = ele.cfd + le.Pod;
			// 					} else {
			// 						ele.cfd = ele.cfd + le.Pod + "、";
			// 					}
			// 				});
			// 			}
			// 			let tag_array = [];
			// 			ele.strengths.forEach((e) => {
			// 				tag_array.push(e.strength);
			// 			});
			// 			ele.tag_array = tag_array;
			// 		});
			// 		this.product_group = res.objects;
			// 	});
			// },
			getThemeProductList(id) {
				getThemeProductList(
					this.page,
					this.search
				).then((res) => {
					res.result.tag.tagProductList.forEach((ele) => {
						ele.xqsnTheme = ele.xqsnTheme.split(",")
					});
					this.product_group = res.result.tag.tagProductList
				});
			},
			//获得周边游产品列表
			getTicketList() {
				getTicketList(this.page, this.search == "" ? 15 : 16, this.search, this.type).then(
					(res) => {
						this.max_page = this.search == "" ? 15 : 16;
						this.total = res.result.tag.count;
						this.product_group = [];
						res.result.tag.tagProductList.forEach((e) => {
							let tag_array = [];
							tag_array.push(e.themeName);
							e.tag_array = tag_array;
						});
						this.product_group = res.result.tag.tagProductList;
					}
				);
			},

			//获得东北定制游产品列表
			getCustomCityProductList(newVal) {
				let podId = 0;
				switch (newVal) {
					case 0:
						podId = 3087;
						break;
					case 1:
						podId = 3101;
						break;
					case 2:
						podId = 3110;
						break;
					case 3:
						podId = 3088;
						break;
				}

				let platformid;
				if (this.$app == "dy") platformid = "8";
				else if (this.$app == "kh") platformid = "7";

				getCustomCityProductList(platformid, podId, this.page, 16).then((res) => {
					this.max_page = 16;
					this.total = res.total;
					res.objects.forEach((ele) => {
						ele.pd_json = ele.pd_json != "" ? JSON.parse(ele.pd_json) : "";
						if (ele.pd_json != "") {
							ele.cfd = "";
							ele.pd_json.pod.forEach((le, i) => {
								if (i + 1 == ele.pd_json.pod.length) {
									ele.cfd = ele.cfd + le.Pod;
								} else {
									ele.cfd = ele.cfd + le.Pod + "、";
								}
							});
						}
						let tag_array = [];
						ele.strengths.forEach((e) => {
							tag_array.push(e.strength);
						});
						ele.tag_array = tag_array;
					});
					this.product_group = res.objects;
				});
			},
			// getGWTeamHotCity出境游热门
			getGWTeamHotCity() {
				getGWTeamHotCity().then((res) => {
					console.log(res)
					this.destination_group = [];
					res.DesCurrencies.forEach((e) => {
						this.destination_group.push({
							id: e.Id,
							name: e.Name,
						});
					});
				});
			},
			// 出境游
			getOutboundTravel() {
				let platformid;
				if (this.$app == "dy") platformid = "8";
				else if (this.$app == "kh") platformid = "7";
				getOutboundTravelList(
					platformid,
					this.page,
					this.search == "" ? 15 : 16,
					this.search
				).then((res) => {
					this.max_page = this.search == "" ? 15 : 16;
					this.total = res.total;
					res.objects.forEach((ele) => {
						ele.pd_json = ele.pd_json != "" ? JSON.parse(ele.pd_json) : "";
						if (ele.pd_json != "") {
							ele.cfd = "";
							ele.pd_json.pod.forEach((le, i) => {
								if (i + 1 == ele.pd_json.pod.length) {
									ele.cfd = ele.cfd + le.Pod;
								} else {
									ele.cfd = ele.cfd + le.Pod + "、";
								}
							});
						}
						let tag_array = [];
						ele.strengths.forEach((e) => {
							tag_array.push(e.strength);
						});
						ele.tag_array = tag_array;
					});
					this.product_group = res.objects;
				});
			},
			//获得跟团游产品
			getGroupHotCity() {
				getGroupHotCity().then((res) => {
					this.destination_group = [];
					res.GN.slice(1).forEach((e) => {
						this.destination_group.push({
							id: e.Id,
							name: e.Name,
						});
					});
				});
			},
			getProvinceProduct() {
				let platformid;
				if (this.$app == "dy") platformid = "8";
				else if (this.$app == "kh") platformid = "7";
				getProvinceProductList(
					platformid,
					this.page,
					this.search == "" ? 15 : 16,
					this.search
				).then((res) => {
					this.max_page = this.search == "" ? 15 : 16;
					this.total = res.total;
					res.objects.forEach((ele) => {
						ele.pd_json = ele.pd_json != "" ? JSON.parse(ele.pd_json) : "";
						if (ele.pd_json != "") {
							ele.cfd = "";
							ele.pd_json.pod.forEach((le, i) => {
								if (i + 1 == ele.pd_json.pod.length) {
									ele.cfd = ele.cfd + le.Pod;
								} else {
									ele.cfd = ele.cfd + le.Pod + "、";
								}
							});
						}
						let tag_array = [];
						ele.strengths.forEach((e) => {
							tag_array.push(e.strength);
						});
						ele.tag_array = tag_array;
					});
					this.product_group = res.objects;
				});
			},
			getGroupProduct() {
				let platformid;
				if (this.$app == "dy") platformid = "8";
				else if (this.$app == "kh") platformid = "7";
				getGroupProductList(
					platformid,
					this.page,
					this.search == "" ? 15 : 16,
					this.search
				).then((res) => {
					this.max_page = this.search == "" ? 15 : 16;
					this.total = res.total;
					res.objects.forEach((ele) => {
						ele.pd_json = ele.pd_json != "" ? JSON.parse(ele.pd_json) : "";
						if (ele.pd_json != "") {
							ele.cfd = "";
							ele.pd_json.pod.forEach((le, i) => {
								if (i + 1 == ele.pd_json.pod.length) {
									ele.cfd = ele.cfd + le.Pod;
								} else {
									ele.cfd = ele.cfd + le.Pod + "、";
								}
							});
						}
						let tag_array = [];
						ele.strengths.forEach((e) => {
							tag_array.push(e.strength);
						});
						ele.tag_array = tag_array;
					});
					this.product_group = res.objects;
				});
			},
		},
	};
</script>

<style lang='less' scoped>
	.product-list-total {
		display: flex;
		width: 100vw;
		flex-direction: column;
		align-items: center;
		background-color: #f6f6f6;

		.page {
			margin-top: 30px;
			margin-bottom: 50px;
		}

		.red-bar {
			display: flex;
			position: relative;
			width: 100vw;
			height: 1508px;
			justify-content: center;

			.red-img {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 1508px;
				z-index: 0;
			}

			.red-img2 {
				position: absolute;
				top: 5px;
				left: 618px;
				width: 222px;
				height: 64px;
				z-index: 0;
			}
		}

		.product-bar {
			display: flex;
			z-index: 1;
			width: 1200px;
			flex-direction: column;

			.product-more-bar {
				display: flex;
				width: 100%;
				flex-wrap: wrap;
			}
		}
	}
</style>